import { CompanySettingsVM, ML_Company } from './dto';
/*import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';*/

import { DateTime } from "luxon"; //https://moment.github.io/luxon/docs/manual/formatting.html
import {
    SaleBillLineItemView,
    TaxView,
    TaxAppliedView,
    TaxAppliedDTO,
    GlobalSettingsVM,
    InwardLineItemView,
    ML_Transaction,
    ML_SaleBill, ML_Customer, ML_Tag
} from "./dto";
import * as nw from '../globals/numberToWords';
import * as AppAct from '../globals/AppActions';
import converter from 'number-to-words';
import { AppReduxState } from "../infra-redux/AppStateReduxStore";
import { GlobalSettingsDTO } from "./data.dto";

export const generateHash = () => {
    return new Date().getTime()
}

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}



export const convertNumberToWords = (state: AppReduxState, num: number) => {
    //let GlobSets : GlobalSettingsVM = JSON.parse(localStorage.getItem(AppAct.GLOBAL_SETTINGS));
    //const state = AppStore.getState();
    let GlobSets: GlobalSettingsDTO = GetGlobalSettingsObject(state.appReduxer.GlobalSettings);
    if (GlobSets.CurrencyName === 'INR')
        return nw.numWords(num);
    else
        return converter.toWords(num);
}

export const GetGlobalSettingsObject = (json: string): GlobalSettingsDTO => {
    if (isEmptyOrSpaces(json))
        return new GlobalSettingsDTO();
    else
        return JSON.parse(json);
}

export const formatCurrencyValue = (state: AppReduxState, num: number) => {

    //let GlobSets : GlobalSettingsVM = JSON.parse(localStorage.getItem(AppAct.GLOBAL_SETTINGS));
    //const state = AppStore.getState();
    let GlobSets: GlobalSettingsDTO = GetGlobalSettingsObject(state.appReduxer.GlobalSettings);
    //console.log('num to fix',num,GlobSets);
    if (num !== undefined && num !== null && GlobSets) {
        num = +num;
        //console.log(GlobSets.CurrencyRegion + " : " + GlobSets.CurrencyName);
        if (GlobSets.CurrencyName && GlobSets.CurrencyRegion)
            return Intl.NumberFormat(GlobSets.CurrencyRegion, { maximumFractionDigits: 2, currency: GlobSets.CurrencyName, style: 'currency' }).format(num);
        else
            return num.toFixed(2);
        //return Intl.NumberFormat('en-IN', { maximumFractionDigits:2, currency:'INR', style:'currency' }).format(num);
    }
    else
        return '';
}

export const RoundNumber = (num: number) => {
    return num.toFixed(0);
}

export const calculateDiscountAndTaxes = (lineItemView: SaleBillLineItemView, taxSelected: TaxView) => {
    if (lineItemView.LineProduct && lineItemView.LineProduct.UnitMeasure && lineItemView.LineItem.BulkQty > 0) {
        lineItemView.LineItem.RetailQty = lineItemView.LineItem.BulkQty * lineItemView.LineProduct.UnitMeasure.RetailQty;
        let LineTotal = 0;

        //Calculate subtotal for RETAIL qty
        if (+lineItemView.LineItem.IsCalcDoneOnBulkQty === 0)
            LineTotal = lineItemView.LineItem.RetailQty * lineItemView.LineItem.PricePerBulkUnit;

        //Calculate subtotal for BULK qty
        if (+lineItemView.LineItem.IsCalcDoneOnBulkQty === 1)
            LineTotal = lineItemView.LineItem.BulkQty * lineItemView.LineItem.PricePerBulkUnit;

        if (LineTotal > 0 && lineItemView.LineItem.PricePerBulkUnit) {
            //% discount
            if (+lineItemView.LineItem.DiscountPercentage > 0) {
                lineItemView.LineItem.DiscountedAmount = (LineTotal * +lineItemView.LineItem.DiscountPercentage) / 100;
                lineItemView.LineItem.SubTotal = LineTotal - lineItemView.LineItem.DiscountedAmount;
            }
            //Amount discount
            else if (+lineItemView.LineItem.DiscountedAmount > 0) {
                lineItemView.LineItem.SubTotal = LineTotal - lineItemView.LineItem.DiscountedAmount;
            } else
                lineItemView.LineItem.SubTotal = LineTotal;
        }

        //untaxed amount
        lineItemView.LineItem.AmountUntaxed = lineItemView.LineItem.SubTotal;
        lineItemView.LineItem.AmountTaxed = 0;

        if (taxSelected === null && lineItemView.LineItem.AmountTaxDetailsJson) {
            taxSelected = JSON.parse(lineItemView.LineItem.AmountTaxDetailsJson);
            taxSelected.Slabs = JSON.parse(taxSelected.TaxSetting.SlabSettingsJson);
        }

        //calculate tax on Untaxed amount
        if (taxSelected) {
            lineItemView.TaxDetails = new TaxAppliedView();
            lineItemView.TaxDetails.SlabsApplied = new Array<TaxAppliedDTO>();
            lineItemView.TaxDetails.TaxSetting = taxSelected.TaxSetting;
            taxSelected.Slabs?.forEach((x) => {
                if (x.TaxValue != 0 && lineItemView.LineItem.AmountUntaxed != 0) {
                    let aptx = new TaxAppliedDTO();
                    aptx.TaxName = x.TaxName;
                    aptx.TaxValue = x.TaxValue;
                    aptx.TotalTaxedAmount = (lineItemView.LineItem.AmountUntaxed * aptx.TaxValue) / 100;
                    lineItemView.LineItem.AmountTaxed += aptx.TotalTaxedAmount;
                    lineItemView.TaxDetails.SlabsApplied.push(aptx);
                }
            });

            //Complete total of Untaxed amount + taxes
            lineItemView.LineItem.SubTotal =
                lineItemView.LineItem.AmountUntaxed +
                lineItemView.LineItem.AmountTaxed;

            //save tax amounts as JSON for future reference
            if (lineItemView.LineItem.AmountTaxed != 0) {
                lineItemView.LineItem.AmountTaxDetailsJson = JSON.stringify(lineItemView.TaxDetails);
            }
        }
    }
    return lineItemView;
}

export const isEmptyOrSpaces = (str: string): boolean => {

    if (isObjectUndefined(str)) {
        return true;
    }

    str = str.replace(' ', '');

    if (str === '') {
        return true;
    }

    if (str === null) {
        return true;
    }

    let result = (str && str.match(/^ *$/) !== null);
    return result;
}

export const isObjectUndefined = (obj: any): boolean => {
    if (obj === null) {
        return true;
    }
    if (obj == '')
        return true;

    return typeof obj === 'undefined';
}

export const isProductionTrackingEnabled = (setting: string) => setting && GetGlobalSettingsObject(setting).EnableProductionTracking;


export const FormTransDetailsMessage = (t: ML_Transaction, cat: ML_Tag) => {
    let msg = '';
    if (cat?.TagName)
        msg = msg + cat.TagName;
    if (t?.Details) {
        if (cat !== null)
            msg = msg + (cat?.TagName ? ' : ' : '') + t.Details;
    }
    return msg;
}

export const DeriveCustomerName = (b: ML_SaleBill, c: ML_Customer) => {
    if (b.PhantomCustomer && b.PhantomCustomer.trim().length > 0) {
        //remove parent <p> tag
        let name = b.PhantomCustomer.trim();
        name = name.substring(3, name.length);
        name = name.substring(0, name.length - 4);
        if (name.length > 0)
            return name;
    }
    if (c !== null)
        return c.CompanyName;
}


export const GetStoreSettings = (store: ML_Company) => {
    if (!isObjectUndefined(store))
        if (!isEmptyOrSpaces(store.SettingsJSON))
            return JSON.parse(store.SettingsJSON);
    return new CompanySettingsVM();
}

export const GetStoreCashPaySetting = (store: ML_Company, trans: ML_Transaction) => {
    let vm = GetStoreSettings(store);
    trans.LedgerID = trans.TotalAmount > 0 && trans.LedgerID > 0 ?
        trans.LedgerID :
        vm.DefaultCashCounterLedgerID;

    trans.CategoryID = trans.CategoryID > 0 ?
        trans.CategoryID :
        vm.DefaultLedgerEntryCategory;
    return trans;
}

export const convertNumberToString = (num: number|string) => {
    if (num === null || num === undefined)
        return '';
    return num.toString();
}
