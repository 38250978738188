import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import * as AppAct from "../globals/AppActions";
import * as dateUtils from '../globals/AppDateUtils';
import * as consts from '../globals/GlobalConsts';
import {
    BrokerPaymentVM,
    CallActionResult,
    CompanySettingsVM,
    CompanyView, InvoiceExpenseView,
    InwardLineItemView,
    InwardView,
    ML_Broker, ML_BrokerPayment,
    ML_Company,
    ML_Inward,
    ML_InwardLineItem,
    ML_Product,
    ML_Supplier,
    ML_Tax,
    ML_Transaction,
    ProductView, SaleBillLineItemView, SaleBillView,
    TaxAppliedDTO,
    TaxAppliedView
} from "../globals/dto";

import * as sdb from '../db/SupplierData';
import * as brdb from '../db/BrokersData';
import * as idb from '../db/InwardsData';
import * as cpdb from '../db/CompanyData';

import Select from 'react-select';
import * as helper from './InwardInvoiceEntryHelper';
import ModalHeader from "react-bootstrap/ModalHeader";
import { Modal, ModalBody } from "react-bootstrap";
import { InwardLineItem } from "./InwardLineItem";
import * as global from "../globals/GlobalConsts";
import { DateControlEx } from "../globals/DateControlEx";
import { toast } from "react-toastify";
import * as utils from '../globals/Utils';
import { ExpenseSection } from "../shared/ExpenseSection";
import StoresSelect from '../shared/StoresSelect';
import { AxiosResponse } from "axios";
import * as factory from '../globals/factory';
import * as sbdb from "../db/SaleBillData";
import SupplierDetails from "../suppliers/SupplierDetails";
import Swal from "sweetalert2";
import { OtherLineItem } from "../shared/OtherLineItem";
import { SupplierSelect } from "../shared/SupplierSelect";
import OnSpotPayByCustomer from "../sales/OnSpotPay";
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';
import PurchaseBrokerage from './PurchaseBrokerage';
import InwardLineItemsView from './InwardLineItemsView';
import InwardItemsTable from './InwardItemsTable';
import { CloseButtonUI } from '../globals/AppControls/CloseButtonUI';
import { SaveButtonUI } from '../globals/AppControls/SaveButtonUI';
import { DeleteButtonUI } from '../globals/AppControls/DeleteButtonUI';
import * as _ from 'lodash';

type attributes = {
    inwardId: number,
    store: ML_Company,
    close(): void,
    show: boolean
}

const InwardInvoiceEntry = (props: attributes) => {
    const appDisp = useDispatch();

    const [validity, setValidity] = useState({
        SupplierInoviceNumberErr: '',
        DespatchedThroughErr: '',
    });

    const [editingIndex, setEditingIndex] = useState(-1);

    const [inwardView, setInwardView] = useState(new InwardView());
    const [cashLedgerId, setCashLedgerId] = useState(0);

    const [linetItem, setLineItem] = useState(new InwardLineItemView());

    //const [suppliers, setSuppliers] = useState(new Array<ML_Supplier>());
    const [supplierSelected, setSupplierSelected] = useState(new ML_Supplier());
    const [supplierLoading, setSupplierLoading] = useState(false);
    const [showSupplierModal, setShowSupplierModal] = useState(false);

    const [expenses, setExpenses] = useState(new Array<InvoiceExpenseView>());

    const [brokers, setBrokers] = useState(new Array<ML_Broker>());
    const [brokerSelected, setBrokerSelected] = useState(new ML_Broker());
    const [brokerLoading, setBrokerLoading] = useState(false);
    const [brokerAutoCalcComm, setBrokerAutoCalcComm] = useState(1);
    //used to get the useEffect trigger calc method to properly get the setState data.
    //Normal calc() method call did not get the latest state values.
    //const [reCalcTrigger, setReCalcTrigger] = useState(false);

    const [showPopUp, setShowPopUp] = useState(false);
    const [popUpHeader, setPopUpHeadedr] = useState('Popup');

    const POP_HEAD_LINEITEM = "Purchase Line Item";
    const POP_HEAD_EXPENES = "Expense";

    const [isChangePayAmount, setIsChangePayAmount] = useState(false);
    const [oldPayAmount, setOldPayAmount] = useState(0);

    const createInwardView = () => {
        let copy = new InwardView();
        copy.InwardEntry = new ML_Inward();
        copy.InwardEntry.SupplierInoviceNumber = '';
        copy.InwardEntry.DespatchedThrough = '';
        copy.InwardEntry.DeliveryNote = '';
        copy.InwardEntry.SupplierDated = dateUtils.SanitizeDate(new Date());
        copy.InwardEntry.InvoiceReceivedDate = dateUtils.SanitizeDate(new Date());
        copy.InwardEntry.RowCreateDate = dateUtils.SanitizeDate(new Date());

        if (props.store) {
            copy.InwardEntry.CompanyID = props.store.CMPID;
            copy.Company = props.store;
        }
        copy.SupplierDetails = factory.createSupplier();

        copy.InwardEntry.GrandTotal = 0;
        copy.InwardEntry.TotalBulkQty = 0;
        copy.InwardEntry.TotalRetailQty = 0;

        copy.BrokerPayVM = factory.createBrokerVM();
        copy.AdditionalExpense = new Array<InvoiceExpenseView>();
        copy.CashPayTrans = factory.createTransactionML();
        decideLedgerAndCategoryId(copy);
        //setInwardView(copy);
    }

    const updateInwardObjectAndTotal = (iv: InwardView) => calculateAllTotals(iv);


    useEffect(() => {
        if (props.inwardId > 0) {
            appDisp(AppAct.ToggleProgress(true));
            idb.GetInwardById(props.inwardId).then(res => {
                appDisp(AppAct.ToggleProgress(false));
                let iv = res;
                if (!iv.CashPayTrans)
                    iv.CashPayTrans = factory.createTransactionML();
                decideLedgerAndCategoryId(iv);
            })
        } else {
            createInwardView();
        }

        appDisp(AppAct.ChangeMainTitle('Purchase Invoice'));

    }, []);

    const calculateAllTotals = (iv: InwardView) => {

        if (inwardView.InwardEntry) {
            //Invoice *****************************************
            inwardView.InwardEntry.GrandTotal = 0;
            inwardView.InwardEntry.TotalBulkQty = 0;
            inwardView.InwardEntry.TotalRetailQty = 0;
            inwardView.InwardEntry.AmountUnTaxed = 0;
            inwardView.InwardEntry.AmountTax = 0;

            inwardView.LineItems.forEach(m => {

                if (m.LineItem.OnFlyItemPrice !== 0)
                    m.LineItem.ProductID = 0;
                else
                    m.LineItem.ProductID = m.LineProduct.Product.PRDID;

                //only non-cancled items
                if (m.LineItem.IsCanceled === false) {
                    inwardView.InwardEntry.GrandTotal += m.LineItem.LineTotal;
                    inwardView.InwardEntry.TotalBulkQty = +inwardView.InwardEntry.TotalBulkQty + +m.LineItem.BulkQty;
                    inwardView.InwardEntry.TotalRetailQty += m.LineItem.RetailQty;

                    inwardView.InwardEntry.AmountTax += m.LineItem.AmountTaxed;
                    inwardView.InwardEntry.AmountUnTaxed += m.LineItem.AmountUntaxed;
                }
            });



            //Broker *****************************************
            // if (brokerSelected.BRKID > 0) {
            //     if (!inwardView.BrokerPayVM) {
            //         inwardView.BrokerPayVM = new BrokerPaymentVM();
            //         inwardView.BrokerPayVM.TransactionEntry = new ML_Transaction();
            //         inwardView.BrokerPayVM.BrokerPay = new ML_BrokerPayment();
            //     }
            //     inwardView.BrokerPayVM.Broker = brokerSelected;
            //     inwardView.BrokerPayVM.BrokerPay.TotalQty = inwardView.InwardEntry.TotalBulkQty;
            //     inwardView.BrokerPayVM.TransactionEntry.TotalAmount = brokerSelected.CommissionAmount * inwardView.InwardEntry.TotalBulkQty;
            // 	if(brokerAutoCalcComm === 1){ //Auto calc, or else, consider, manual entry
            //         inwardView.BrokerPayVM.TransactionEntry.TotalAmount = brokerSelected.CommissionAmount * inwardView.InwardEntry.TotalBulkQty;
            //     } 
            // }

            //Expense *****************************************
            inwardView.AdditionalExpTotal = 0;
            inwardView.AdditionalExpense.forEach(m => {
                inwardView.AdditionalExpTotal = inwardView.AdditionalExpTotal + +m.Transaction.TotalAmount;
                if (m.IsDeductInInvoice === 1) {
                    inwardView.InwardEntry.GrandTotal = inwardView.InwardEntry.GrandTotal - m.Transaction.TotalAmount;
                }
            });

            //if (inwardView.InwardEntry.PayableTotalAmount === 0 || inwardView.InwardEntry.PayableTotalAmount < inwardView.InwardEntry.GrandTotal)
            inwardView.InwardEntry.PayableTotalAmount = inwardView.InwardEntry.GrandTotal;

            setInwardView(iv);
        }
    }

    const closePopUp = (reFetchData: boolean = false) => {
        setShowPopUp(false);
        if (reFetchData) {
            if (popUpHeader === POP_HEAD_EXPENES) {
            }
            if (popUpHeader === POP_HEAD_LINEITEM) {
            }
        }
    }

    const popUpLineItem = (li: InwardLineItemView, editx: number) => {
        setEditingIndex(editx);

        let newItem = new InwardLineItemView();
        newItem.LineItem = new ML_InwardLineItem();
        newItem.LineItem.BulkQty = 0;
        newItem.LineItem.LineTotal = 0;
        newItem.LineItem.LineItemOrderNumber = 0;
        newItem.LineProduct = new ProductView();
        newItem.LineProduct.Product = new ML_Product();
        newItem.TaxDetails = new TaxAppliedView();
        newItem.TaxDetails.SlabsApplied = new Array<TaxAppliedDTO>();
        newItem.TaxDetails.TaxSetting = new ML_Tax();

        !li ? setLineItem({ ...newItem }) : setLineItem({ ...li });

        setPopUpHeadedr(POP_HEAD_LINEITEM);
        setShowPopUp(true);
    }

    const lineItemSaved = (lineItem: InwardLineItemView) => {

        if (editingIndex > -1) {
            inwardView.LineItems[editingIndex] = lineItem;
        } else {
            inwardView.LineItems.push(lineItem);
        }

        /*if(lineItem.LineItem.LineItemOrderNumber === 0) {
            inwardView.LineItems.push(lineItem);
            inwardView.LineItems.map((m:InwardLineItemView, idx:number)=>m.LineItem.LineItemOrderNumber = idx+1);
        } else {
            inwardView.LineItems[lineItem.LineItem.LineItemOrderNumber-1] = lineItem;
        }*/
        calculateAllTotals(inwardView);
        closePopUp();
    }

    const lineItemDelete = (lineItem: InwardLineItemView, index: number) => {
        inwardView.LineItems[index].LineItem.BulkQty = 0;
        inwardView.LineItems[index].LineItem.Rate = 0;
        //inwardView.LineItems.splice(index,1);
        //setInwardView(iv);
        //setReCalcTrigger(!reCalcTrigger);
        calculateAllTotals(inwardView);
    }

    const getLatestExpenses = (trans: InvoiceExpenseView[]) => {
        setExpenses(trans);
        inwardView.AdditionalExpense = trans;
        calculateAllTotals(inwardView);
        //setReCalcTrigger(!reCalcTrigger);
    }

    const handleSupplierChange = (e: any) => {
        console.log("Inward check: " + JSON.stringify(e));
        if (e.SPID === -1) {
            setShowSupplierModal(true);
        } else {
            setSupplierSelected(e);
            //inwardView.SupplierDetails = e;
            inwardView.InwardEntry.SupplieID = e.SPID;
            inwardView.SupplierDetails = e;
            setInwardView({ ...inwardView });
        }
    }

    const handleInvoiceChange = (e: any) => {
        let name = e.target.name;
        let val = isNaN(e.target.value) ? e.target.value : +e.target.value;

        if (name === 'brokerManualComm') {
            //set value here -> InwardView.BrokerPayVM.TransactionEntry.TotalAmount
            setInwardView({
                ...inwardView,
                BrokerPayVM: {
                    ...inwardView.BrokerPayVM,
                    "TransactionEntry": { ...inwardView.BrokerPayVM.TransactionEntry, "TotalAmount": +val, "Details": "Lump-sum commission" }
                }
            });
        }
        else if (name === 'AmountPaid') {
            setInwardView({ ...inwardView, [name]: val });
        } else
            setInwardView({ ...inwardView, InwardEntry: { ...inwardView.InwardEntry, [name]: val } });
        //validateData();
    }

    const handleDateEntry = (name: string, date: any) => {

        let dateObj: Date = new Date(date);

        console.log("Date : ", date);
        if (name === 'InvoiceReceivedDate') {
            inwardView.InwardEntry.InvoiceReceivedDate = dateObj;
        } else if (name === 'SupplierDated') {
            inwardView.InwardEntry.SupplierDated = dateObj;
        } else if (name === 'BuyerOrderDated') {
            inwardView.InwardEntry.BuyerOrderDated = dateObj;
        }
        setInwardView({ ...inwardView });
    }

    const decideLedgerAndCategoryId = (iv: InwardView) => {
        iv.CashPayTrans = utils.GetStoreCashPaySetting(iv.Company, iv.CashPayTrans);
        setInwardView({ ...iv });
    }

    const handleStoreSelection = (s: ML_Company) => {
        setInwardView({ ...inwardView, Company: { ...s }, InwardEntry: { ...inwardView.InwardEntry, CompanyID: s ? s.CMPID : 0 } })
    }

    const invoiceSave = () => {
        if (helper.validateData(inwardView)) {
            toast.dismiss();
            appDisp(AppAct.ToggleProgress(true));

            let inwardCopy = { ...inwardView };
            inwardCopy.SupplierDetails = supplierSelected;
            inwardCopy.BrokerPayVM.BrokerPay.DateCreated = new Date();
            inwardCopy.LineItems = inwardCopy.LineItems.filter(f => f.LineItem.ProductID > 0 || f.LineItem.OnFlyItemPrice > 0); //remove empty entries.
            
            //Bug fix for shit reasons
            inwardCopy.InwardEntry.SupplierInoviceNumber = utils.convertNumberToString(inwardCopy.InwardEntry.SupplierInoviceNumber);
            inwardCopy.InwardEntry.RowUpdateDate = inwardCopy.InwardEntry.RowCreateDate;
            inwardCopy.InwardEntry.DespatchedThrough = utils.convertNumberToString(inwardCopy.InwardEntry.DespatchedThrough);

            setInwardView(inwardCopy);
            console.log('saving', inwardCopy);
            idb.Save(inwardCopy).then(res => {
                if (res === null || res.data === null) {
                    console.log(res);
                    toast.error('Something went wrong, please retry');
                } else {
                    appDisp(AppAct.ToggleProgress(false));
                    //props.history.push('/inwards');
                    props.close();
                }
            }, (error) => { console.log("After post error occurred: " + JSON.stringify(error)) });
        }
    }

    const invoiceCancel = () => {

        toast.dismiss();
        Swal.fire({
            title: 'Cancel Bill',
            html: "Are you sure want to cancel this bill?",
            showCancelButton: true,
            confirmButtonColor: 'red',
            focusCancel: true,
        }).then((res: any) => {
            if (res.value) {
                appDisp(AppAct.ToggleProgress(true));
                idb.CancelBill(inwardView.InwardEntry.INWID).then((res: AxiosResponse) => {
                    appDisp(AppAct.ToggleProgress(false));
                    if (res.data.StatusCode === consts.CALL_RESPONSE_STATUS_ERROR) {
                        toast.error(res.data.Message);
                    } else {
                        props.close();
                    }
                });
            }
        })
    }

    if (!props.show) return <></>

    if (props.show && inwardView && inwardView.InwardEntry && inwardView.InwardEntry.INWID != null)
        return (
            <>
                <div className="row align-items-center">
                    <div className="col-md-3">
                        <h4 className="az-content-label mb-0 pb-0">
                            {inwardView.InwardEntry.INWID > 0 ? `Invoice #${inwardView.InwardEntry.INWID}` : 'New Invoice'}
                        </h4>
                    </div>
                    <div className="col-md-5">
                        <div className="d-flex gap-1 align-items-center">
                            <label className="mb-0 pb-0">Store</label>
                            <div className="flex-grow-1">
                                <StoresSelect onChange={handleStoreSelection} company={inwardView.Company} />
                            </div>
                        </div>
                    </div>
                    <div className="col text-right">
                        {inwardView.InwardEntry.IsCanceled === false && inwardView.InwardEntry.INWID > 0 &&
                            <DeleteButtonUI buttonText='Cancel Bill' className="mr-2" onClick={invoiceCancel} />}
                        {inwardView.InwardEntry.IsCanceled === false &&
                            <SaveButtonUI onClick={invoiceSave} className="mr-2" />}
                        <CloseButtonUI onClick={() => props.close()} />
                    </div>
                </div>
                <div className="form-row mt-3">
                    <div className="col-md-7 pr-md-5">
                        <div className="form-row">
                            <div className="col-12">
                                <label className="form-label">Supplier</label>
                                <SupplierSelect supplierSelected={inwardView.SupplierDetails}
                                    supplierChanged={handleSupplierChange}></SupplierSelect>
                            </div>
                            {+supplierSelected.SPID > 0 &&
                                <div className="col-12 mt-3">
                                    <label className="form-label">Supplier Details</label>
                                    <div className="table-responsive mb-0">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "50%" }} className="font-small">Company's
                                                        GSTIN/UIN<br /><b>{supplierSelected.GSTN}</b></td>
                                                    <td className="font-small">Bank
                                                        Name<br /><b>{supplierSelected.CompanyBankName}</b>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="font-small">Company's CST
                                                        No.<br /><b>{supplierSelected.CSTNo}</b>
                                                    </td>
                                                    <td className="font-small">A/C
                                                        No<br /><b>{supplierSelected.CompanyAccountNumber}</b></td>
                                                </tr>
                                                <tr>
                                                    <td className="font-small">Company's
                                                        PAN<br /><b>{supplierSelected.PAN}</b>
                                                    </td>
                                                    <td className="font-small">Branch &amp; IFSC<br /><b>{supplierSelected.BranchIFSCCode}</b>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-md-5">

                        <div className="form-row">
                            <div className="col-12 pl-md-5">
                                <p className="invoice-info-row pl-md-5">
                                    <label className="form-label font-normal pt-2 wd-200-f">Invoice No:<span
                                        className="text-danger">*</span></label>
                                    <input type="text"
                                        className={"form-control " + validity.SupplierInoviceNumberErr}
                                        name={"SupplierInoviceNumber"}
                                        value={inwardView.InwardEntry.SupplierInoviceNumber}
                                        onChange={handleInvoiceChange} />
                                </p>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-12 pl-md-5">
                                <p className="invoice-info-row pl-md-5">
                                    <label className="form-label font-normal pt-2 wd-200-f">Invoice Date:<span
                                        className="text-danger">*</span></label>
                                    <DateControlEx
                                        datevalue={inwardView.InwardEntry.SupplierDated}
                                        //placeholder={utils.FormatDate(inwardView.InwardEntry.SupplierDated)}
                                        onDateChange={(e: string) => {
                                            handleDateEntry('SupplierDated', e)
                                        }} />
                                </p>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-12 pl-md-5">
                                <p className="invoice-info-row pl-md-5">
                                    <label className="form-label font-normal pt-2  wd-200-f">Vehicle No:<span
                                        className="text-danger">*</span></label>
                                    <input type="text"
                                        className={"form-control " + validity.DespatchedThroughErr}
                                        name={"DespatchedThrough"}
                                        value={inwardView.InwardEntry.DespatchedThrough}
                                        onChange={handleInvoiceChange} />
                                </p>
                                <hr />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-12 pl-md-5">
                                <p className="invoice-info-row pl-md-5">
                                    <label className="form-label font-normal pt-2 wd-200-f">Today's Date:<span
                                        className="text-danger">*</span></label>
                                    <DateControlEx
                                        datevalue={inwardView.InwardEntry.InvoiceReceivedDate}
                                        //value={inwardView.InwardEntry.InvoiceReceivedDate}
                                        onDateChange={(e: string) => {
                                            handleDateEntry('InvoiceReceivedDate', e)
                                        }} />
                                </p>
                            </div>
                        </div>
                        <div className="form-row mb-2 ">
                            <div className="col-12 pl-md-5">
                                <p className="invoice-info-row pl-md-5">
                                    <label className="form-label font-normal pt-2 wd-200-f">Delivery Notes:</label>
                                    <input type="text" className="form-control"
                                        name={"DeliveryNote"}
                                        value={inwardView.InwardEntry.DeliveryNote}
                                        onChange={handleInvoiceChange} />
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                {/* ----------------- PURCHASE ITEMS --------------- */}
                <InwardItemsTable
                    onInwardBillViewUpdate={updateInwardObjectAndTotal}
                    inwardView={inwardView}
                />

                {/* ----------------- BROKERAGE --------------- */}
                <PurchaseBrokerage
                    inwardView={inwardView}
                    onInwardViewUpdate={updateInwardObjectAndTotal}
                />

                {/* ----------------- OTHER EXPENSES --------------- */}
                <h5 className='mt-4 az-content-label mt-3'>Other Expenses</h5>
                <div className="form-row">
                    <div className="col-md-12">
                        <ExpenseSection trans={inwardView.AdditionalExpense} getLatest={getLatestExpenses} />
                    </div>
                </div>

            </>
        );

    return <>Unable to process request</>

}

export default InwardInvoiceEntry;
