import React, {useState} from 'react';
import * as utils from './../globals/Utils';

export const SearchAutoHideControl = (props: any) => {
    const [txtSearch, setTxtSearch] = useState('');
    const [showInput, setShowInput] = useState(false);

    const onFocusLeft = ()=> {
        setShowInput(!utils.isEmptyOrSpaces(txtSearch))
    }

    return <>
        <div onClick={()=>setShowInput(true)} className="d-flex align-items-center justify-content-center flex-row gap-1 pointer-cursor">
            <div><i className="bi-search tx-16"/></div>
            {!showInput &&
                <div>Search</div>
            }
            {showInput &&
                <input type="search"
                       className="form-control"
                       placeholder="Search by Customer"
                       onBlur={onFocusLeft}
                       onChange={(e:any)=>setTxtSearch(e.target.value)}
                /> }
        </div>
    </>
}
