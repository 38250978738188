import React, { useEffect, useState } from 'react';

const Toggler = (props: any) => {

    const [toggle, setToggle] = useState<boolean>(props.status);

    const toggleSwitch = () => {
        props.onToggle(!toggle, props.name);
        setToggle(!toggle);
    }

    return (
        <div className="d-flex flex-row align-items-center pointer-cursor cm-toogle-div">
            <div>
                <input className="cm-toggle"
                    type="checkbox"
                    defaultChecked={props.value}
                    onChange={toggleSwitch}
                />
            </div>
            <div className="ml-1 pb-1">
                {props.text}
            </div>
        </div>

        // <div onClick={toggleSwitch} className="pointer-cursor">
        //     {toggle &&
        //         <div className="d-flex flex-row align-items-center">
        //             <div>
        //                 <ToggleOn {...props} />
        //             </div>
        //             <div className="ml-1">
        //                 {props.text}
        //             </div>
        //         </div>
        //     }

        //     {!toggle &&
        //         <div className="d-flex flex-row align-items-center">
        //             <div>
        //                 <ToggleOff {...props}/>
        //             </div>
        //             <div className="ml-1">
        //                 {props.text}
        //             </div>
        //         </div>
        //     }
        // </div>
    )

}

export default Toggler;
