//KLP
export const global_api_url_base = 'https://lab.srushti-soft.com';

//Pruthvi mill
//export const global_api_url_base = 'http://trackdata.srushtisoft.com';

//Muddalli
//export const global_api_url_base = 'http://trackerdata.srushtisoft.com';


//development
//export const global_api_url_base = "http://localhost:6060";

//VS IDE
//export const global_api_url_base = "http://localhost:9739";
//CLI
//export const global_api_url_base = "http://localhost:5000";


//Azure server
//export const global_api_url_base = 'http://20.57.136.235:200/'

//LINUX
//export const global_api_url_base = 'http://127.0.0.1:5000/';
