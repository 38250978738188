import React, {useEffect, useRef, useState} from "react";
import {OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import {LocationOutline} from "react-ionicons";
import * as utils from '../../globals/Utils';
import {ML_Broker, ML_Customer} from "../dto";
import * as bdb from '../../db/BrokersData';

type attributes = {
    cssClasses: string,
    id: number,
    elementKey: number
}

export const BrokerPopTag = (props: attributes) => {
    const [info, setInfo] = useState<ML_Broker>(null);
    const keyPrefix = 'bro'
    const FetchInfo = () => {
        if (info === null && props.id > 0) {
            bdb.getOnId(props.id).then(res => {
                setInfo(res.data);
            })
        }
    }

    const popoverContent =
        <Popover id={props.elementKey + 'popSub' + keyPrefix} className="shadow-ex-1 border border-gray" placement={'top'}>
            <Popover.Body>
                {!info && <Spinner animation={"border"} variant={"secondary"}/>}
                {info && <>
                    <div className="d-flex flex-column gap-2">
                        <div className="font-weight-bold">{info.BrokerName}</div>
                        {!utils.isEmptyOrSpaces(info.ContactNumber) &&
                            <div><i className="bi-telephone-outbound" />&nbsp;{info.BrokerName}</div>
                        }
                        {!utils.isEmptyOrSpaces(info.GeoCity) &&
                            <div><LocationOutline />&nbsp;{info.GeoCity}</div>
                        }
                    </div>
                </>}
            </Popover.Body>
        </Popover>

    return (
        <OverlayTrigger key={props.elementKey + 'pop' + keyPrefix} placement="top" rootClose trigger="click"
                        onToggle={() => FetchInfo()} overlay={popoverContent}>
            <span className={props.cssClasses + " bg-dark"}>Broker</span>
        </OverlayTrigger>
    )
}
