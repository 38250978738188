import React, { useEffect, useState } from 'react';
import { ReportFilter } from '../globals/dto';
import ReportFilterControls from './report-filter-controls';
import Toggler from '../globals/Toggler';
import { uuidv4 } from '../globals/Utils';
import { Modal } from 'react-bootstrap';


const ReportFilterPane =(props:any)=>{

    const [filters, setFilters] = useState<ReportFilter[]>(props.filters);
    const [selectedFilter, setSelectedFilter] = useState<ReportFilter>(null);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [informParent, setInformParent] = useState(false);

    useEffect(()=>{
        if(!props.filters)
            setFilters(new Array<ReportFilter>());
        else 
            setFilters(props.filters);
    },[props.filters]);

    useEffect(()=>{
        props.onChanged(filters);
    },[informParent])


    const toggleEnableItem = (uuid: string) => {
        let copy = [...filters];
        let index = copy.findIndex(x => x.uuid === uuid);
        copy[index].isEnabled = !copy[index].isEnabled;
        return copy;
    }

    const filterToggled = (tog: boolean, uuid: string) => {
        setFilters([...toggleEnableItem(uuid) ]);
        setInformParent(!informParent);
    }

    const addNewFilter = () =>{
        let newFilter = new ReportFilter();
        newFilter.uuid = uuidv4();
        newFilter.FilterName= '';
        console.log("partent " + newFilter.uuid);
        setSelectedFilter(newFilter);
        setShowFilterModal(true);
    }

    const saved = (fi : ReportFilter) => {
        console.log(JSON.stringify(fi,null,2));
        
        let copy = [...filters];
        if(!copy)
            copy = new Array<ReportFilter>();

        let idx = copy.findIndex(f => f.uuid === fi.uuid);

        if(idx > -1)
            copy.splice(idx,1,fi);
        else
            copy.push(fi);
        
        setFilters(copy);
        setInformParent(!informParent);
    }

    const closed = () =>{
        setShowFilterModal(false);
    }

    const remove = (uuid: string) => {
        let copy = [...filters];
        if(!copy)
            copy = new Array<ReportFilter>();

        let idx = filters.findIndex(f=>f.uuid === uuid);
        copy.splice(idx,1);
        setFilters(copy);
        setInformParent(!informParent);
    }

    const edit = (idx : number) => {
        setSelectedFilter({...filters[idx]});
        setShowFilterModal(true);
    }

    return(
        <>
            <button className="btn btn-primary mb-1" onClick={addNewFilter}>+Add New Filter</button>
            {/* <input type="text" value={colSearch} className="form-control mb-3" placeholder="Search..." onChange={(e)=>setColSearch(e.target.value)}/> */}
            {filters && filters.map((c: ReportFilter, idx: number) => {
                                        return <div className="d-flex flex-row mt-1" key={'fli' + idx}>
                                            <div className="pointer-cursor mr-2" onClick={()=>remove(c.uuid)}>
                                                <i className="bi-trash text-danger" />
                                            </div>
                                            <div className="flex-grow-1 pointer-cursor" onClick={()=>edit(idx)} >{c.FilterName}</div>
                                            <div><Toggler
                                                onToggle={filterToggled}
                                                status={c.isEnabled}
                                                name={c.uuid}
                                                className="h3 text-black pointer-cursor ml-2" />
                                            </div>
                                        </div>
                                    })}

        {selectedFilter &&
            <Modal show={showFilterModal} onHide={()=>closed} dialogClassName="modal-corner" backdrop={'static'}>
                <ReportFilterControls columns={props.columns} filter={selectedFilter} onSave={saved} onClosed={closed}></ReportFilterControls>
            </Modal>
        }
        </>
    );
}

export default ReportFilterPane;
