import axios, { AxiosResponse } from 'axios';
import { CallActionResult, CreditPayAgentView, InwardLineItemView, InwardTransactionVM, InwardView, ML_Inward, ML_Transaction } from "../globals/dto";
import { global_api_url_base } from "../globals/ApiUrlConsts";
import * as factory from '../globals/factory';
import * as dateUtils from '../globals/AppDateUtils';

const base_inward_api = global_api_url_base + '/api/inwards';

export const GetInwards = async (start: Date, end: Date): Promise<AxiosResponse<Array<InwardView>>> =>
    await axios.get(base_inward_api + '/GetInwards', { params: { start: dateUtils.SanitizeDate(start), end: dateUtils.SanitizeDate(end) } });
//await axios.get(base_inward_api + '/GetInwards?start=' + start.toISOString() + '&end=' + end.toISOString());

export const GetInwardsWithLinteItems = async (start: Date, end: Date): Promise<AxiosResponse<Array<InwardView>>> =>
    await axios.get(base_inward_api + '/GetInwardsWithLinteItems',
        { params: { start: dateUtils.SanitizeDate(start), end: dateUtils.SanitizeDate(end) } });
//await axios.get(base_inward_api + '/GetInwardsWithLinteItems?start=' + start.toISOString() + '&end=' + end.toISOString());

export const GetProductViewOnLineItemID = async (liid: number) =>
    await axios.get(base_inward_api + '/GetProductViewOnLineItem', { params: { li: liid } });

export const GetInwardItems = (id: number) => axios.get(base_inward_api + '/GetLines?inid=' + id.toString())

export const GetBayInwards = () => axios.get(base_inward_api + '/GetPendingInwards')

export const GetInwardById = async (iwid: number): Promise<InwardView> =>
    await axios.get(base_inward_api + '/GetInwardDetails?iwid=' + iwid)
        .then(res => {

            let data = res.data;
            if (data) {
                data.LineItems.map((x: InwardLineItemView) => {
                    if (x.LineItem.AmountTaxDetailsJson)
                        x.TaxDetails = JSON.parse(x.LineItem.AmountTaxDetailsJson)
                });

                if (data.InwardEntry) {
                    data.InwardEntry.InvoiceReceivedDate = new Date(data.InwardEntry.InvoiceReceivedDate);
                    data.InwardEntry.SupplierDated = new Date(data.InwardEntry.SupplierDated);
                }

                if (!data.BrokerPayVM) data.BrokerPayVM = factory.createBrokerVM();

                if (!data.AdditionalExpense) {
                    data.AdditionalExpense = new Array<ML_Transaction>();
                }
                // else {
                //     data.AdditionalExpense.map((x: ML_Transaction) => {
                //         x.TransDate = new Date(x.TransDate);
                //         x.LastUpdateDate = new Date(x.LastUpdateDate);
                //     })
                // }
            }
            return data;
        });

export const GetItemTrackInProcess = (liid: number) => axios.get(base_inward_api + '/TrackItemInProcess?lineID=' + liid)

export const Save = async (entry: InwardView) => {
    return await axios.post(base_inward_api + '/save', entry);
}

export const CancelBill = async (billID: number) => await axios.post<AxiosResponse<CallActionResult>>(base_inward_api + '/CancelBill',
    null, { params: { inwid: billID } })

export const UpdateBillStatus = async (bid:number, entry:string, value:boolean) => await axios.post(base_inward_api + '/UpdateStatus',null,{params: {bid, entry, value}})

export const ChangeBillPayAmount = async (inwid: number, amount: number) =>
    await axios.post(base_inward_api + '/UpdatePay', null, { params: { inwid, amount } })

export const UpdateInternalQtyAndBatch = (entry: InwardView) => axios.post(base_inward_api + '/UpdateInternalQtyAndBatch', entry)

//LEVEL - 1 - SUPPLIER WISE
export const getAllCreditorsEx = async () => await axios.get(base_inward_api + '/GetCreditorsEx')

//LEVEL - 2 - SUPPLIER - INWARD - WISE
export const getSupplyInwardDetailsEx = async (spid: number, start: Date, end: Date) =>
    await axios.get(base_inward_api + '/GetSupplierInwardWiseCreditStatmentEx',
        { params: { spid: spid, start: dateUtils.SanitizeDate(start), end: dateUtils.SanitizeDate(end)} })


//LEVEL - 3 - SUPPLIER - INWARD - LINEITEM WISE
export const getAllCreditorPayPendingDetailsEx = async (spid: number) =>
    await axios.get(base_inward_api + '/GetCreditDetailsToPayEx?supid=' + spid)

// export const getAllCreditorPayPendingDetails = async (spid : number) =>
//     await axios.get(base_inward_api + '/GetCreditDetailsToPay?supid='+spid)

export const saveCreditPayments = async (entry: CreditPayAgentView) =>
    await axios.post(base_inward_api + '/SavePayments', entry)

export const payCredit = async (pay: InwardTransactionVM) =>
    await axios.post(base_inward_api + '/PayCreditEx', pay)

export const getPayHistory = async (spid: number) =>
    await axios.get(base_inward_api + '/PaymentsHistory?supid=' + spid)

export const searchSupplierInward = async (sid: number, term: string) =>
    await axios.get(base_inward_api + '/SearchSupplierInward', { params: { term: term, spid: sid } })
