import React, {useState} from 'react';
import * as dateUtils from './../globals/AppDateUtils';
import {DateControlEx} from "../globals/DateControlEx";
import DateRangeControlEx from "../globals/DateRangeControlEx";

export const DateRangeAutoHideControl = (props: {
    start: Date,
    end :Date,
    onChange(st:Date, ed:Date):void
}) => {
    const [showInput, setShowInput] = useState(false);

    const onFocusLeft = ()=> {
        setShowInput(false);
    }

    const showDates = ()=> {
        let s = '';
        if(props.start) s = dateUtils.FormatDate(props.start);
        if(props.end) s = s + ' - ' + dateUtils.FormatDate(props.end);

        if(s === '')
            return '(Start Date -> End Date)';
        return s;
    }

    return <>
        <div onClick={()=>setShowInput(true)} className="d-flex align-items-center justify-content-center flex-row gap-1 pointer-cursor">
            <div><i className="bi-calendar-date tx-16"/></div>
            {!showInput &&
                <div>{showDates()}</div>
            }
            {showInput &&
                <>
                    <DateRangeControlEx start={props.start} end={props.end} onChange={props.onChange} />
                </>
                 }
        </div>
    </>
}
